/* Retard */
.retard-doctor{
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 6rem;
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 70%;
    max-width: 35rem;
    padding-bottom: 3rem;
}

.dropdown{
    text-align: center;
    font-size: 1.3rem;
    height: 3rem;
    width: 10rem;
    margin-bottom: 1em;
    background-color: #5383FF;
    color: white;
    border: 2px solid #5383FF;
    border-radius: 5px;
    appearance: none;
    cursor: pointer;
}

.signup-button {
    font-size: 1.1rem;
    width: 10rem;
    min-height: 2.5rem;
    cursor: pointer;
    color: white;
    background-color: #5383FF;
    border: 1px solid #5383FF;
    border-radius: 5px;
    margin-top: 2rem;
    transition-duration: 100ms;
    text-transform: none;
}
.signup-button:hover {
    border: 1px solid #2551bf;  
    background-color: #2964f8;
    transition-duration: 400ms
}

.updated-label {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #5383FF;
}