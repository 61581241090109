/* Search Bar */
.input-area{
  text-align: center;
}
.search{
  width: 70%;
  max-width: 50rem;
  height: 3rem;
  border: 2px solid #C9C7C7;
  border-radius: 10px;
  padding-left: 2rem;
  font-size: 0.9rem;
}
/* Card */
.cards{
  display: flex;
  width: 70%;
  min-width: 55rem;
  max-width: 70rem;
  justify-content: space-around;
  margin-top: 7rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 900px) {
  .cards{
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 70%;
    min-width: 20rem;
    margin-top: 5rem;
  }
  .card{
    margin-top: 0;
    margin-bottom: 2rem;
  }
}