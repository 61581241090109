.notification-container {
    width: 70%;
    max-width: 33rem;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 8px;
    font-family: Arial, sans-serif;
    margin-bottom: 1rem;
}

.notification-title {
    color: #333;
    text-align: center;
}

.notification-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.notification-form-div {
    display: flex;
    flex-direction: column;
}

.notification-label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.notification-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.notification-button {
    padding: 10px 15px;
    background-color: #5383FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
}

.notification-button:hover {
    background-color: #3a6ed1;
}

@media (max-width: 768px) {
    .notification-container {
      width: 65%;
    }
}
