.welcome-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use full height to center vertically */
    background-color: white; /* Light gray background, change as needed */
    color: #5383FF; /* Dark text color for contrast */
    font-family: 'Arial', sans-serif; /* Use a common stack or your custom font */
  }
  
  .welcome-h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5em;
  }
  
  .welcome-p {
    font-size: 1.25rem;
    margin-bottom: 4.5em;
  }
  
  .welcome-button {
    font-size: 1.5rem;
    color: white;
    background-color: #5383FF;
    border: none;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-right: 3.5rem; /* Equal margin for top/bottom and left/right */
    margin-left: 3.5rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    text-transform: none;
  }
  
  .welcome-button:hover,
  .welcome-button:focus {
    background-color: #2964f8; /* Darker shade on hover/focus */
    transform: scale(1.05); /* Slightly enlarge the buttons when hovered/focused */
    outline: none; /* Remove focus outline, use with caution */
  }

  /* Responsive adjustments */
@media (max-width: 768px) {
  .welcome-h1 {
    font-size: 2rem; /* Smaller font size for smaller screens */
  }

  .welcome-p {
    font-size: 1rem; /* Smaller font size for smaller screens */
    margin-bottom: 2em; /* Less margin to save space */
  }

  .welcome-button {
    font-size: 1.2rem; /* Smaller font size for smaller screens */
    padding: 0.75rem 1.5rem; /* Smaller padding for smaller screens */
    margin: 0 2rem; /* Adjust margins for smaller screens */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .welcome-page {
    padding: 10px; /* Add padding to avoid touching the screen edges */
  }

  .welcome-h1 {
    font-size: 1.5rem; /* Even smaller font size */
  }

  .welcome-button {
    margin: 0 1rem; /* Adjust margins for very small screens */
    padding: 0.5rem 1rem; /* Further reduce padding */
  }
}
