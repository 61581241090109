.login-page-modify{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signup-full-form{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 60%;
  max-width: 20rem;
  height: 50rem;
  margin: 2rem;
  margin-top: 4rem;
  padding: 2rem;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  justify-content: center;
}

.signup-form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 47rem;
  margin-bottom: 2rem;
}

.password-div, .email-div{
  display: flex;
  flex-direction: column;
}

.signup-label{
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #5383FF;
}

.signup-label-red{
  color: red;
}

.login-input{
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
  border: 1.5px solid #C9C7C7;
  border-radius: 5px;
  padding-left: 0.5rem;
}

.signup-button {
  font-size: 1.1rem;
  width: 10rem;
  min-height: 2.5rem;
  cursor: pointer;
  color: white;
  background-color: #5383FF;
  border: 1px solid #5383FF;
  border-radius: 5px;
  margin-top: 2rem;
  transition-duration: 100ms;
  text-transform: none;
}

.avatar {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  margin: 5px;
}
  
.avatar {
  width: 7.5rem;
  height: 7.5rem;
}
  
.avatar-button {
  font-size: 1rem;
  width: 8.5rem;
  min-height: 2.2rem;
  cursor: pointer;
  color: white;
  background-color: #5383FF;
  border: 1px solid #5383FF;
  border-radius: 5px;
  margin-top: 2rem;
  transition-duration: 100ms
}

.avatar.selected {
  border-color: #5383FF; /* Change to your preferred highlight color */
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.avatar-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr); /* Adjust as needed */
  gap: 1rem;
}

.selected-avatar-image {
  margin-top: 0.7rem;
  width: 4.5rem; /* Adjust as needed */
  height: 4.5rem;
  border: 1.5px solid #5383FF;
  border-radius: 5px;
  cursor: pointer;
}

.delete-account-button {
  font-size: 1.1rem;
  width: 10rem;
  min-height: 2.5rem;
  cursor: pointer;
  color: white;
  background-color: red;
  border: 1px solid red;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition-duration: 100ms
}