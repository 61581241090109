.login-page{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.right-form-a, .right-form-b{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    max-width: 20rem;
    height: 13.4rem;
    margin: 2rem;
    margin-top: 4rem;
    padding: 2rem;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.right-form-a {
    background-color: #5383FF;
    border: 2px solid #5383FF;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.login-label-a{
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.link{
    text-decoration: none;
    color: #031941;
}

.create-button-a{
    font-size: 1.1rem;
    width: 10rem;
    min-height: 2.5rem;
    cursor: pointer;
    color: #5383FF;
    background-color:white;
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 2rem;
    transition-duration: 100ms;
    text-transform: none;
}
  
.mail-button {
    font-size: 1.1rem;
    width: 8rem;
    min-height: 2rem;
    cursor: pointer;
    color: white;
    background-color: #5383FF;
    border: 1px solid #5383FF;
    border-radius: 5px;
    margin-top: 1rem;
    transition-duration: 100ms;
    padding-top: 0.7rem;
    text-decoration: none;
}

.login-full-form{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 60%;
    max-width: 20rem;
    height: 35rem;
    margin: 2rem;
    margin-top: 4rem;
    padding: 2rem;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    justify-content: center;
}

.login-form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20rem;
  margin-bottom: 2rem;
}
.password-div, .email-div{
  display: flex;
  flex-direction: column;
}
.login-label, .signup-label{
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #5383FF;
}
.signup-label{
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.signup-label-red{
  color: red;

  animation: shake 0.2s;

  animation-iteration-count: 1;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.login-input{
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
  border: 1.5px solid #C9C7C7;
  border-radius: 5px;
  padding-left: 0.5rem;
}
.login-button, .signup-button, .create-button{
  font-size: 1.1rem;
  width: 10rem;
  min-height: 2.5rem;
  cursor: pointer;
  color: white;
  background-color: #5383FF;
  border: 1px solid #5383FF;
  border-radius: 5px;
  margin-top: 2rem;
  transition-duration: 100ms;
  text-transform: none;
}




.login-button:hover, .signup-button:hover, .create-button:hover{
  border: 1px solid #2551bf;  
  background-color: #2964f8;
  transition-duration: 400ms
}

.create-button{
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.create-account{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
  

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-full-form {
    height: 15rem;
    margin-bottom: 1rem;
  }
  .login-label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .login-page {
    flex-direction: column;
    align-items: center;
  }

  .right-form-a, .right-form-b{
      width: 70%; /* Increase width slightly on smaller tablets */
      margin: 0;
      margin-top: 1rem;
      height: 6rem;
  }
  .login-label-a {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .right-form-a, .right-form-b, .login-full-form {
      width: 95%; /* Use more screen width on very small devices */
      margin: 1rem 0; /* Reduce margin to utilize space better */
  }

  .create-button-a, .mail-button, .login-button, .signup-button, .create-button {
      max-width: 100%; /* Allow buttons to fill the container */
  }
}