.profile-box-patient-page{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 14rem;
    width: 70%;
    max-width: 35rem;
}
.profile-picture-patient-page{
    height: 13rem;
    width: 13rem;
    margin-top: auto;
    margin-bottom: auto;
}
.infos-patient-page{
    width: 40%;
    max-width: 14rem;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.5rem;
    padding-right: 2rem;
}
.name-patient-page{
    font-size: 1.5rem;
}

@media (max-width: 490px) {
    .profile-picture-patient-page{
        height: 10rem;
        width: 9rem;
    }
    .infos-patient-page{
        font-size: 1.3rem;
    }
    .name-patient-page{
        font-size: 1.3rem;
    }
}
@media (max-width: 380px) {
    .profile-picture-patient-page{
        height: 8rem;
        width: 8rem;
    }
    .infos-patient-page{
        font-size: 1rem;
    }
    .name-patient-page{
        font-size: 1.1rem;
    }
}