.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  
  .loading-bar {
    width: 100%;
    max-width: 300px;
    height: 20px;
    border: 2px solid #5383FF;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .loading-bar::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: #5383FF;
    border-radius: 8px;
    animation: fillBar 3s linear forwards;
  }
  
  @keyframes fillBar {
    to {
      width: 100%;
    }
  }
  
  .loading-text {
    margin-top: 15px;
    font-size: 1.2rem;
    color: #333;
  }
  