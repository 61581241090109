.signup-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 2rem;
}

.signup-title {
  font-size: 2.5rem;
  color: #5383FF;
  margin: 0;
  padding-top: 1.5rem;
  margin-bottom: 3.5rem;
  text-align: center;
  font-weight: 500;
}

.big-u {
  font-size: 3rem;
}

.form-group {
  margin-bottom: 20%;
  height: 25rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-input {
  width: 85%;
  padding: 15px;
  padding-left: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  border: 1px solid rgb(147, 145, 145);
  border-radius: 4px;
  font-size: 0.9rem;
}

.form-next-btn, .form-back-btn {
  font-size: 1.5rem;
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content (useful if there are multiple lines of text or other elements) */
  margin-left: auto;
  margin-right: auto;
  width: 91%;
  padding: 15px 15px; /* Adjust padding as needed */
  background-color: #5383FF; /* Your button's blue */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 1rem;
}

.form-back-btn {
  background-color: #888;
}

.already {
  background-color: rgb(5, 88, 5);
  color: white;
}

.password {
  margin-top: -1rem;
  margin-bottom: 0.25rem;
}

.confirm {
  margin-top: -0.5rem;
}

.error-label {
  color: red;
  text-align: center;
  margin-top: -1.5rem;
}

.avatar-selector {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: -2rem;
}

.avatar {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  margin: 5px;
}

.avatar {
  width: 7.5rem;
  height: 7.5rem;
}

.avatar-button {
  font-size: 1rem;
  width: 8.5rem;
  min-height: 2.2rem;
  cursor: pointer;
  color: white;
  background-color: #5383FF;
  border: 1px solid #5383FF;
  border-radius: 5px;
  margin-top: 2rem;
  transition-duration: 100ms
}

.avatar.selected {
  border-color: #5383FF; /* Change to your preferred highlight color */
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.avatar-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr); /* Adjust as needed */
  gap: 1rem;
}

.selected-avatar-image {
  margin-top: 0.7rem;
  width: 4.5rem; /* Adjust as needed */
  height: 4.5rem;
  border: 1.5px solid #5383FF;
  border-radius: 5px;
  cursor: pointer;
}