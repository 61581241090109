/* Navbar */
.navbar-doctor-page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 0.8rem;
    height: 5rem;
    background-color: #5383FF;
    color: white;
    font-size: 2rem;
}
.title-doctor-page{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.profile-picture-doctor-page{
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}
/* Logout Button Style */
.logout-button {
    color: white;
    /* background-color: #ff4d4d; Red color for logout to indicate action */
    border: none;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.75rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}